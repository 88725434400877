<template>
	<router-link :to="{ name: 'Blog Article', params: { id: id }}">
        <div class="project-card options" :style="{'background-image': 'url(' + getImage(plant_trees.img) + ')'}">
            <img src="@/assets/trip/tick-circle.png" alt="tick circle" class="tick-circle"/>
            <div class="card-title">
				<div class="text">
					Choose
					<span class="large">
						Projects
					</span>
				</div>
			</div>
        </div>
    </router-link>
</template>

<script>
import helpers from '@/helpers/helpers.js';

export default {
	name: 'ProjectCard',
	props: ['title', 'img', 'id'],
	data() {
		return {
			plant_trees: {
				img: "trip/plant-trees",
			},
		}
	},
	methods: {
		getImage: (img) => helpers.getImage(img),
	},
};
</script>

<style scoped>
	.project-card {
		position: relative;
		width: 100%;
		height: 100%;
		border-radius: 8px;
		border: none;
	}
	.project-card .image {
		width: 162px;
		height: 214px;    
		border-radius: 8px;
	}
	.project-card .card-title {
		position: absolute;
		top: 35%;
		width: 100%;
		color: #ffffff;
		font-weight: 600;
		font-size: 16px;
		text-align: center;
		line-height: 26px;
		padding-top: 11px;
	}
	.projects .slider .text {
		color: #ffffff;
		font-size: 24px;
		font-weight: 700;
		width: 100%;
		position: absolute;
		top: 35%;
	}
	.projects .text .large {
		display: block;
		font-size: 64px;
		line-height: 60px;
	}
	.projects .options {
		position: relative;
		margin: 0 auto;
		width: 280px;
		height: 320px;
	}
	.projects .options .tick-circle {
		position: absolute;
		bottom: 35px;
		left: 128px;
	}
	a {
		text-decoration: none;
		color: #000;
	}
</style>