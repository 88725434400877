<template>
	<div class="add-pax" @click="$emit('click')">
		<div class="circle"></div>
		<img class="plus" src="@/assets/travellers/add-traveller.png" alt="Add Traveller Icon" />
		<span class="text">{{ type }}</span>
	</div>
</template>

<script>
export default {
	props: {
		type: {
			type: String,
			default: "Add",
		}
	}
}
</script>

<style scoped>
	.add-pax {
		display: inline-block;
		position: relative;
		cursor: pointer;
		margin-right: 20px;
	}
	.add-pax .circle {
		background-color: #A8BFC6;
		width: 62px;
		height: 62px;
		border-radius: 32px;
	}
	.add-pax .plus {
		position: absolute;
		top: 17px;
		left: 17px;
		width: 28px;
	}
	.add-pax .text {
		text-align: center;
		width: 40px;
		font-size: 12px;
		color: #C4C4C4;
		background-color: transparent;
		display: inline-block;
		width: 100%;
	}
</style>