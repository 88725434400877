<template>
	<div class="pax" @click="$emit('click')">
	
		<div class="picture-wrapper">
			<div class="picture">
				<div v-if="profilePicture" class="image">Profile Pic Here</div>
				<div v-else class="initials">{{ initials }}</div>
			</div>
			<span class="type" :class="type" v-if="type"></span>
		</div>

		<div class="name">
			{{ name }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		name: {
			type: String
		},
		lastName: {
			type: String,
			default: "",
		},
		type: {
			type: String,
		},
		image: { // TODO. Will this be a URL?
			type: String,
			default: "",
		},
		profilePicture: {
			default: false,
		}
	},
	computed: {
		firstInitial() {
			return this.name.charAt(0);
		},
		initials() {
			return this.name.charAt(0) + this.lastName.charAt(0);
		},
	},
}
</script>

<style scoped>
	.pax {
		width: 74px;
	}
	.pax .picture {
		width: 62px;
		height: 62px;
		border-radius: 31px;
		overflow: hidden;
		display: inline-block;
	}
	.pax .picture .initials {
		text-transform: uppercase;
		background-color: #118AB2;
		width: 100%;
		height: 100%;
		text-align: center;
		line-height: 62px;
		color: white;
		font-size: 32px;
		font-weight: 700;
	}
	.pax .picture-wrapper {
		display: inline-block;
		position: relative;
	}
	.pax .type {
		display: inline-block;
		width: 29px;
		height: 29px;
		background-size: 29px;
		position: absolute;
		right: -11px;
		bottom: 3px;
	}
	.pax .type.Adult {
		background-image: url("../../assets/travellers/adult-icon.png");
	}
	.pax .type.Child {
		background-image: url("../../assets/travellers/child-icon.png");
	}
	.pax .type.Infant {
		background-image: url("../../assets/travellers/infant-icon.png");
	}
	.pax .name {
		font-weight: 700;
		font-size: 12px;
		text-align: center;
		max-width: 62px;
		word-break: break-word;
	}
</style>