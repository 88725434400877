<template>
	<div class="project-card-slider">
		<div class="title-container">
			<img src="@/assets/menu/leaf-icon-main.png" alt="leaf" class="leaf"/>
			<div>
				{{slider_title}}
			</div>
			<div class="selected">
				X of Y Selected
			</div>
		</div>
		<swiper ref="project-card-slider" :options="swiperOptions" class="blog-card-slider">
			<swiper-slide v-for="(card, index) in cards" :key="index">
				<ProjectCard v-bind:title="card.fields.heading" v-bind:img="card.fields.featured_image" :id="card.id" />
			</swiper-slide>
			<div class="swiper-button-prev" slot="button-prev"></div>
	     	<div class="swiper-button-next" slot="button-next"></div>
		</swiper>
	</div>
</template>

<script>
import ProjectCard from '@/components/sliders/ProjectCard.vue'
import contentService from '@/services/contentService.js'

export default {
	name: 'ProjectCardSlider',
	data() {
		return {
			swiperOptions: {
				slidesPerView: "auto",
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			},
			cards: []
		}
	},
	components: {
		ProjectCard,
	},
	props: ['slider_title'],
	methods: {
		fetchPosts: function() {
			contentService.latestPosts()
				.then((response) => {
					this.cards = response.data;
				});
		},
	},
	created() {
		this.fetchPosts();
	}
}

</script>

<style scoped>
	.projects img.leaf {
		display: block;
		margin: 30px auto;
		width: 20px;
	}
	.projects div.selected {
		font-size: 20px;
		color: #60D2A3;
		margin: 30px auto;
		margin-bottom: 16px;
	}
	.blog-card-slider .swiper-slide {
		width: 280px;
		height: 320px;
		margin: 0px 9px;
		border-radius: 16px;
		background: #60D2A3;
	}
	.blog-card-slider .swiper-slide:nth-child(1) {
		margin-left: 15px;
	}
	.project-card-slider .title {
		font-weight: 800;
		font-size: 18px;
		line-height: 28px;
		margin-left: 15px;
	}
	.project-card-slider .btn {
		float: right;
		width: 69px;
		height: 23px;
		margin-right: 10px;
	}
	.project-card-slider .title-container {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.swiper-button-prev, .swiper-button-next {
		top: 40%;
	}
</style>
