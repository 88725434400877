<template>
	<div class="price summary-table">
		<div v-for="(group, groupKey) in groupedTripItems" :key="groupKey">
			<div v-if="group.items.length > 0">
				<div class="inner-wrapper">
					<div class="heading">{{ group.heading }}</div>
					<div class="section-heading">Prices</div>
				</div>
				<div v-for="(tripItem) in group.items" :key="tripItem.id">
					<!-- {{ tripItem }} -->
					<div class="inner-wrapper">
						<div class="item-heading">{{ tripItem.name }}</div>
					</div>
					<div class="section">
						<div class="table-row" v-if="tripItem.type == 'flight'">
							<div class="name type flight">Flight</div>
							<div class="data" v-if="tripItem.data.flights">Total <span class="type flight">{{ tripItem.data.flights.length }}</span> flights</div>
						</div>
						<div class="table-row" v-else-if="tripItem.type == 'tour'">
							<div class="name type tour">Tour</div>
						</div>
						<div  v-for="(value, key) in tripItem.travellerTypePricing" :key="key">
							<div class="table-row" v-if="tripItem.totalTravellers[key] > 0">
								{{ key }} (£{{ value.price }} x {{ tripItem.totalTravellers[key] }})
								<span class="price">£{{ value.price * tripItem.totalTravellers[key] }}</span>
							</div>
						</div>
						<div v-if="tripItem.alerts.all.length > 0 && tripItem.bookingStatus.code != 'unknown'">
							<div v-for="(alert, index) in tripItem.alerts.all" :key="index">
								<div v-if="alert.alert_type == 'traveller_validation'" class="table-row warning">
									<span>{{ alert.main_message }}</span><button class="button tiny" @click="manageTripItemTravellers(tripItem.id)">Manage Travellers</button>
								</div>
								<div v-else-if="alert.alert_type == 'fare'" class="table-row warning"> 
									<span>No fare selected</span><button class="button tiny" @click="viewFlightDetails(tripItem.id)">Select Fare</button>
								</div>
								<div v-else-if="alert.alert_type == 'price'" class="table-row warning"> 
									<span>The price has changed</span><button class="button tiny" @click="viewFlightDetails(tripItem.id)">Confirm</button>
								</div>
								<div v-else-if="alert.alert_type == 'deadline'"> 
									<!-- we don't want to show alerts for these in this area -->
								</div>
								<div v-else> <!-- Generic fallback for other alerts that we haven't hard coded above. Link depends on type of item -->
									<div v-if="tripItem.type == 'flight'" class="table-row warning">
										{{ alert.main_message}}<button class="button tiny" @click="viewFlightDetails(tripItem.id)">Continue</button>
									</div>
									<div v-else-if="tripItem.type == 'tour'" class="table-row warning">
										{{ alert.main_message}}<button class="button tiny" @click="viewTourDetails(tripItem.id)">Continue</button>
									</div>
								</div>
							</div>
						</div>
						<div v-if="tripItem.bookingStatus.code == 'unknown'">
							<div class="table-row warning">
								<span>Unknown booking status. Something has gone wrong.</span>
								<button v-if="tripItem.type == 'flight'" class="button tiny" @click="viewFlightDetails(tripItem.id)">View Details</button>
								<button v-else class="button tiny" @click="viewTourDetails(tripItem.id)">View Details</button>
							</div>
						</div>
						<div class="table-row bold">
							<div class="name">Total Cost</div>
							<div class="data" v-html="'£' + tripItem.price"></div>
						</div>
						<div class="table-row status" :class="tripItem.bookingStatus.code == 'not_booked' ? 'yellow' : tripItem.bookingStatus.code == 'booked' ? 'green' : 'red'">
							<div class="name">Booking Status</div>
							<div class="data">{{ tripItem.bookingStatus.text }}</div>
						</div>
						<div class="table-row status" :class="tripItem.bookingStatus.totalPaid == 0 ? 'red' : tripItem.bookingStatus.totalPaid == tripItem.price ? 'green' : 'yellow'">
							<div class="name">Payment Status</div>
							<div class="data" v-html="tripItem.bookingStatus.totalPaid == 0 ? 'Not Paid' : tripItem.bookingStatus.totalPaid == tripItem.price ? 'Paid' : 'Partially Paid £' + tripItem.bookingStatus.totalPaid"></div>
						</div>
					</div>
				</div>
				<div class="table-row big">
					<div class="name">Total</div>
					<div class="data">£{{ group.totalPrice }}</div>
				</div>
				<div v-if="groupKey == 'Bookable' && showBookButton && true"> <!-- disabled book button for now. See branch multi-booking for working version -->
					<button class="button book" @click="$emit('book')">Book now!</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import router from '@/router';

export default {
	props: {
		tripID: {
			type: Number,
			required: true,
		},
		groupedTripItems: {
			type: Object,
			required: true,
			default: () => {},
			// Should be formatted like this example below. The name of the key is used as the heading for that group. They will be grouped separately.
			// { 
			// 	'Bookable': {
			// 		items: tripItemsCategorized.bookable.items.concat(tripItemsCategorized.bookableWithIssues), 
			// 		totalPrice: tripItemsCategorized.bookable.totalPrice,
			// 		heading: 'Bookable Item Summary'
			// 	},
			// 	'Booked': {
			//		items: tripItemsCategorized.booked.items.concat(tripItemsCategorized.bookedWithIssues), 
			// 		totalPrice: tripItemsCategorized.booked.totalPrice,
			// 		heading: 'Booked Item Summary'
			// 	},
			// }
		},
		showBookButton: {
			type: Boolean,
			required: false,
			default: false,
			// Will show the book button if true. Will only apply to the groupedTripItems with the key 'Bookable'.
		}
	},
	data() {
		return {
		}
	},
	components: {
	},
	computed: {
		
	},
	methods: {
		viewFlightDetails(tripItemID) {
			router.push({
				name: "Saved Flights Details",
				params: {
					trip_id: this.tripID,
					trip_item_id: tripItemID
				},
			}).catch((e) => {
				// Fallback to just scroll up the page with a smooth animation, in case the user is already on the page.
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				});
			});
		},
		viewTourDetails(tripItemID) {
			router.push({
				name: "Saved Tour Details",
				params: {
					trip_id: this.tripID,
					trip_item_id: tripItemID
				},
			}).catch((e) => {
				// Fallback to just scroll up the page with a smooth animation, in case the user is already on the page.
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				});
			});
		},
		manageTripItemTravellers(tripItemID) {
			// Check if the current route is the trip page or a trip item page. Load appropriate page for managing trip passengers
			if(this.$route.name == "Trip") {
				router.push({
					name: "Trip Manage Trip Item Travellers",
					params: {
						trip_id: this.tripID,
						trip_item_id: tripItemID
					},
				});
			} else {
				router.push({
					name: "Manage Trip Item Travellers",
					params: {
						trip_id: this.tripID,
						trip_item_id: tripItemID
					},
				});
			}
		},
	},
	created() {
	
	}
}
</script>

<style>
	/* Styling is done in App.vue */
</style>