import { render, staticRenderFns } from "./ProjectCard.vue?vue&type=template&id=90d01080&scoped=true&"
import script from "./ProjectCard.vue?vue&type=script&lang=js&"
export * from "./ProjectCard.vue?vue&type=script&lang=js&"
import style0 from "./ProjectCard.vue?vue&type=style&index=0&id=90d01080&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90d01080",
  null
  
)

export default component.exports